import { useMemo } from 'react';
import { useColorScheme } from 'react-native-appearance';

import { DarkTheme, DefaultTheme } from 'src/theme';

const extraThemeOptions = {
  spacing (v: number) {
    return v * 8;
  },
};

export const useTheme = () => {
  const colorScheme = useColorScheme();
  const theme = useMemo(() => colorScheme === 'dark' ? DarkTheme : DefaultTheme, [colorScheme]);
  return { ...theme, ...extraThemeOptions };
};
