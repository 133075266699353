import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useNav } from 'src/hooks/useNav';
import { UseStackNavType } from 'src/types/nav';

const DiscussionDetailsScreen = () => {
  useNav<UseStackNavType, StackNavigationOptions>({
    title: 'Schedule details',
    headerBackTitle: 'Back',
  });

  return (
    <View style={styles.container}>
      <Text>DiscussionDetailsScreen</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DiscussionDetailsScreen;
