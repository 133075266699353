import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase';
import React, { useState } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useForm } from 'react-hook-form';
import { StyleSheet, TextInputProps, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Button, Divider, HelperText, IconButton, Surface, TextInput, Title } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useUser } from 'src/hooks/useAuth';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useTheme } from 'src/hooks/useTheme';
import { UseStackNavType } from 'src/types/nav';
import { simpleAlert } from 'src/utils/alert';
import { captureException } from 'src/utils/sentry';

// TODO: fix any
type InputTypes = {
  fieldState: ControllerFieldState;
  field: ControllerRenderProps;
} & TextInputProps;

const Input: React.FC<InputTypes> = ({
  fieldState,
  field,
  ...props
}: InputTypes) => {
  const { onChange, onBlur, value } = field;
  const { isDirty, error } = fieldState;
  return (
    <View style={styles.row}>
      <TextInput
        onChangeText={onChange}
        onBlur={onBlur}
        value={value}
        returnKeyType="next"
        error={isDirty && !!error?.message}
        autoCapitalize="none"
        {...props}
      />
      {isDirty && !!error?.message ? <HelperText type="error">{error?.message}</HelperText> : null}
    </View>
  );
};

type FormDataType = {
  name: string;
  phone: string;
  email: string;
}

const ProfileForm: React.FC = () => {
  const theme = useTheme();
  const { top, bottom } = useSafeAreaInsets();
  const nav = useNavigation<UseStackNavType>();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const { isBrowser } = useDeviceDetect();
  const { userData, setUserData, authUser } = useUser();

  const onSubmit = async (data: FormDataType) => {
    if (!authUser) return;
    setLoading(true);
    const { email, phone, name } = data;
    const db = firebase.firestore();

    const actions = [
      email !== authUser.email ? authUser.updateEmail(email) : Promise.resolve(),
      name !== authUser.displayName ? authUser.updateProfile({ displayName: name }) : Promise.resolve(),
      phone !== userData?.phone ? db.collection('users').doc(authUser.uid).set({ phone }, { merge: true }) : Promise.resolve(),
    ];

    try {
      Promise.allSettled(actions).then(([emailStatus, nameStatus, phoneStatus]) => {
        const errors = [];
        if (emailStatus.status === 'rejected') errors.push(`Email update failed\n${emailStatus.reason.message}`);
        if (nameStatus.status === 'rejected') errors.push(`Name update failed\n${nameStatus.reason.message}`);
        if (phoneStatus.status === 'rejected') {
          errors.push(`Phone update failed\n${phoneStatus.reason.message}`);
        } else {
          setUserData({ ...userData, phone });
        }
        if (errors.length) {
          simpleAlert('Error', errors.join('\n'));
        }
      });
      simpleAlert('Success', 'Updated your profile data');
    } catch (e) {
      simpleAlert('ERROR', e.message || 'Profile update failed');
      captureException(e);
    }
    setLoading(false);
  };

  return (
    <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
      <Surface style={[styles.wrap, isBrowser ? styles.iPadWrap : undefined]}>
        <View style={[styles.topBar, { paddingTop: 8 + top }]}>
          <IconButton icon="close" onPress={() => nav.goBack()} />
        </View>
        <Divider />
        <ScrollView contentContainerStyle={[styles.content]}>
          <Title style={styles.title}>Edit Profile {isBrowser ? 'isTablet' : null}</Title>
          <Controller
            key={authUser?.displayName}
            control={control}
            rules={{
              required: 'Name is required',
            }}
            name="name"
            defaultValue={authUser?.displayName}
            render={({ field, fieldState }) => (
              <Input
                label="Name"
                field={field}
                fieldState={fieldState}
                autoCompleteType="name"
                keyboardType="default"
                autoCapitalize="words"
              />
            )}
          />
          <Controller
            key={authUser?.email}
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            }}
            name="email"
            defaultValue={authUser?.email}
            render={({ field, fieldState }) => (
              <Input
                label="Email"
                field={field}
                fieldState={fieldState}
                autoCompleteType="email"
                keyboardType="email-address"
                autoCapitalize="none"
              />
            )}
          />
          <Controller
            key={userData?.phone}
            control={control}
            rules={{
              required: 'Phone is required',
              pattern: {
                value: /^[789]\d{9}$/,
                message: 'Please enter 10 digit valid phone number',
              },
            }}
            name="phone"
            defaultValue={userData?.phone}
            render={({ field, fieldState }) => (
              <Input
                label="Phone"
                field={field}
                fieldState={fieldState}
                autoCompleteType="tel"
                keyboardType="phone-pad"
                autoCapitalize="none"
              />
            )}
          />
          {/* <TextInput label="Email" /> */}
        </ScrollView>

        <View style={{ paddingBottom: 16 + bottom, padding: 16 }}>
          <Button
            icon=""
            mode="contained"
            onPress={handleSubmit(onSubmit)}
            style={styles.btn}
            labelStyle={styles.btnText}
            loading={loading}
            disabled={loading}
          >Register</Button>
        </View>

      </Surface>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrap: {
    flex: 1,
    width: '100%',
    height: '100%',
    elevation: 9,
  },
  iPadWrap: {
    maxWidth: 400,
    maxHeight: 600,
  },
  topBar: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: 24,
  },
  title: {
    paddingBottom: 32,
  },
  content: {
    padding: 16,
  },
  row: {
    marginBottom: 24,
  },
  btn: {
  },
  btnText: {
    lineHeight: 32,
  },
});

export default ProfileForm;
