import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ScheduleScreen from 'src/screens/Schedule';
import ScheduleDetailsScreen from 'src/screens/ScheduleDetails';

const Stack = createStackNavigator();

type Props = {
}

const ScheduleContainer: React.FC<Props> = () => (
  <Stack.Navigator initialRouteName="ScheduleScreen">
    <Stack.Screen name="ScheduleScreen" component={ScheduleScreen} />
    <Stack.Screen name="ScheduleDetailsScreen" component={ScheduleDetailsScreen} />
  </Stack.Navigator>
);

export default ScheduleContainer;
