import { useRoute } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { RootRouteProps } from 'src/types/nav';

const ModalScreen = (props: any) => {
  const route = useRoute<RootRouteProps<'ModalScreen'>>();
  const Body: React.FC = useMemo(() => {
    return route.params?.body || (() => null);
  }, [route.params?.body]);

  return <Body {...props} />;
};

export default ModalScreen;
