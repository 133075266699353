import React from 'react';
import { Ionicons } from '@expo/vector-icons';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentOptions,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import ScheduleContainer from 'src/containers/Schedule';
import SettingsContainer from 'src/containers/Settings';
import DiscussionContainer from 'src/containers/Discussion';
import { ScrollViewProps, View, Image } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import logo from 'assets/adaptive-icon.png';
import { useTheme } from 'src/hooks/useTheme';

const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

type IconProps = {
  focused: boolean;
  color: string;
  size: number;
}

const DiscussionTabIcon = ({ color, size }: IconProps) => {
  return <Ionicons name='chatbubble' color={color} size={size} />;
};

const ScheduleTabIcon = ({ color, size }: IconProps) => {
  return <Ionicons name='calendar' color={color} size={size} />;
};

const SettingsTabIcon = ({ color, size }: IconProps) => {
  return <Ionicons name='cog' color={color} size={size} />;
};

function CustomDrawerContent ({
  hideLabels,
  ...props
}: ScrollViewProps & {
  children?: React.ReactNode;
  hideLabels?: boolean;
} & DrawerContentComponentProps<DrawerContentOptions>) {
  return (
    <DrawerContentScrollView {...props}>
      <View style={{ height: 128, alignItems: 'center', justifyContent: 'center' }}>
        <Image style={{ width: '100%', height: 130 }} source={logo} resizeMode='contain' />
      </View>
      <DrawerItemList {...props} labelStyle={hideLabels ? { display: 'none' } : undefined} />
    </DrawerContentScrollView>
  );
}

const MainContainer = (props: any) => {
  const { left } = useSafeAreaInsets();
  const { isLargeScreen, isMobile, isTablet } = useDeviceDetect();
  const theme = useTheme();

  if (isMobile) {
    return (
      <Tab.Navigator
        tabBarOptions={{
          showLabel: true,
          activeTintColor: theme.colors.text,
        }}
        initialRouteName="DiscussionContainer"
      >
        <Tab.Screen
          name="DiscussionContainer"
          component={DiscussionContainer}
          options={{ title: 'Discussion', tabBarIcon: DiscussionTabIcon }}
        />
        <Tab.Screen
          name="ScheduleContainer"
          component={ScheduleContainer}
          options={{ title: 'Schedule', tabBarIcon: ScheduleTabIcon }}
        />
        <Tab.Screen
          name="SettingsContainer"
          component={SettingsContainer}
          options={{ title: 'Settings', tabBarIcon: SettingsTabIcon }}
        />
      </Tab.Navigator>
    );
  }

  return (
    <Drawer.Navigator
      {...props}
      drawerContent={props => <CustomDrawerContent {...props} hideLabels={isTablet} />}
      drawerStyle={{ width: isLargeScreen ? 256 : 64 + left }}
      drawerType="permanent">
      <Drawer.Screen
        name="DiscussionContainer"
        component={DiscussionContainer}
        options={{ title: 'Discussion', drawerIcon: DiscussionTabIcon }}
      />
      <Drawer.Screen
        name="ScheduleContainer"
        component={ScheduleContainer}
        options={{ title: 'Schedule', drawerIcon: ScheduleTabIcon }}
      />
      <Drawer.Screen
        name="SettingsContainer"
        component={SettingsContainer}
        options={{ title: 'Settings', drawerIcon: SettingsTabIcon }}
      />
    </Drawer.Navigator>
  );
};

export default MainContainer;
