import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { AppearanceProvider } from 'react-native-appearance';
import * as Sentry from 'sentry-expo';
import firebase from 'firebase';
import AppLoading from 'expo-app-loading';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import * as Notifications from 'expo-notifications';

import Root from './src/Root';
import { userState } from './src/state/atom';
import { SafeAreaProvider } from 'react-native-safe-area-context';

Sentry.init({
  dsn: 'https://b4cea8b813e742179e2d70768148fffa@o929541.ingest.sentry.io/5878316',
  enableInExpoDevelopment: true,
  debug: __DEV__,
  environment: __DEV__ ? '__DEV__' : 'NON_DEV',
});

const firebaseConfig = {
  apiKey: 'AIzaSyBrbIrj-QoUivlkivK5J8oTuKjV9B1Ote4',
  authDomain: 'qurn-5a83d.firebaseapp.com',
  databaseURL: 'https://qurn-5a83d-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'qurn-5a83d',
  storageBucket: 'qurn-5a83d.appspot.com',
  messagingSenderId: '208709632761',
  appId: '1:208709632761:web:c7e6c2768023066186d828',
  measurementId: 'G-X8G3KK30KM',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function App () {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);
  useEffect(() => firebase.auth().onAuthStateChanged(setUser), []);

  const handleInitializeState = (s: MutableSnapshot) => {
    s.set(userState, (user?.toJSON() || null) as any);
  };

  if (user === undefined) return <AppLoading />;

  return (
    <AppearanceProvider>
      <SafeAreaProvider>
        <StatusBar style="auto" />
        <RecoilRoot initializeState={handleInitializeState}>
          <Root />
        </RecoilRoot>
      </SafeAreaProvider>
    </AppearanceProvider>
  );
}
