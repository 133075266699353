import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useLayoutEffect } from 'react';
import { UseStackNavType, UseTabNavType } from 'src/types/nav';

export function useNav<T extends UseStackNavType | UseTabNavType, P extends StackNavigationOptions | BottomTabNavigationOptions | DrawerNavigationOptions = {}> (options?: Partial<P>) {
  const nav = useNavigation<T>();

  useLayoutEffect(() => {
    if (options) nav.setOptions(options);
  }, [nav, options]);

  return nav;
};
