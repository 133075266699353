import React, { useRef } from 'react';
import { Provider as PaperProvider, Text } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import * as Analytics from 'expo-firebase-analytics';
import { useRecoilValue } from 'recoil';
import { Ionicons } from '@expo/vector-icons';

import AuthContainer from './containers/Auth';
import { useTheme } from './hooks/useTheme';
import MainContainer from './containers/Main';
import { userState } from './state/atom';
import { useUser } from './hooks/useAuth';
import linking from './linking';
import { IconProps } from '@expo/vector-icons/build/createIconSet';
import { useNotification } from 'src/hooks/useNotification';
import { createStackNavigator } from '@react-navigation/stack';
import ModalScreen from './screens/Modal';

const Icon = (props: IconProps<any>) => <Ionicons {...props} />;
const Stack = createStackNavigator();

export default function Root () {
  const theme = useTheme();
  const navigationRef = useRef<any>();
  const routeNameRef = useRef();
  const user = useRecoilValue(userState);

  useUser();

  useNotification({
    shouldRegister: !!user,
    onNotificationReceived: notification => console.log({ notification }),
    onNotificationResponseReceived: event => console.log({ event }),
  });

  return (
    <NavigationContainer
      theme={theme}
      linking={linking}
      fallback={<Text>404</Text>}
      ref={navigationRef}
      onReady={() =>
        (routeNameRef.current = navigationRef.current.getCurrentRoute()?.name)
      }
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute()?.name;

        if (!currentRouteName) {
          await Analytics.setCurrentScreen('NotFound');
        } else if (previousRouteName !== currentRouteName) {
          await Analytics.setCurrentScreen(currentRouteName);
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <PaperProvider theme={theme} settings={{ icon: Icon }}>
        <Stack.Navigator mode="modal" headerMode="none">
          <Stack.Screen name="RootStack" component={user ? MainContainer : AuthContainer} />
          <Stack.Screen name="ModalScreen" component={ModalScreen} options={{
            animationEnabled: true,
            cardStyle: { backgroundColor: 'transparent' },
          }}/>
        </Stack.Navigator>
      </PaperProvider>
    </NavigationContainer>
  );
}
