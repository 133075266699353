import { Platform, Alert } from 'react-native';

type SimpleAlertType = typeof Alert.alert;
export const simpleAlert: SimpleAlertType = (...args) => {
  const [, message, buttons] = args;
  if (Platform.OS === 'web') {
    alert(message);
    if (buttons && buttons[0]) {
      const btn = buttons[0];
      if (btn.onPress) btn.onPress();
    }
    return;
  }
  Alert.alert(...args);
};

// // TODO: support for android
// type AlertPromptType = typeof Alert.prompt;
// export const alertPrompt: AlertPromptType = (...args) => {
//   const [, message, callbackOrButtons] = args;
//   if (Platform.OS === 'web') {
//     const value = prompt(message) as any;
//     if (typeof callbackOrButtons === 'function') {
//       callbackOrButtons(value);
//     } else if (callbackOrButtons && Array.isArray(callbackOrButtons) && callbackOrButtons[0]) {
//       const btn = callbackOrButtons[0];
//       if (btn.onPress && value) btn.onPress(value as any);
//     }
//     return;
//   }
//   if (Platform.OS === 'ios') {
//     return Alert.prompt(...args);
//   }
//   return Alert.prompt(...args);
// };
