import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Avatar, Button, Card, FAB, IconButton, Text } from 'react-native-paper';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useNav } from 'src/hooks/useNav';
import { useTheme } from 'src/hooks/useTheme';
import { UseStackNavType, UseTabNavType } from 'src/types/nav';
import { simpleAlert } from 'src/utils/alert';

const DiscussionScreen = () => {
  const theme = useTheme();
  const { isLargeScreen } = useDeviceDetect();

  const HeaderRightIcon = () => {
    return (
      <IconButton
        icon="filter-outline"
        onPress={() => simpleAlert('INFO', 'Preference screen, to manage feed content relevant to you')}
      />
    );
  };

  useNav<UseTabNavType, StackNavigationOptions>({
    title: 'Discussions',
    headerRight: HeaderRightIcon,
  });

  const nav = useNav<UseStackNavType>();

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer} showsVerticalScrollIndicator={false}>
        <View style={[styles.content, { maxWidth: isLargeScreen ? 600 : undefined }]}>
          <Card style={styles.card} onPress={() => nav.navigate('DiscussionDetailsScreen', { id: '123' })}>
            <Card.Title title="Your query" subtitle="Detail explanation of your query" />
            <Card.Actions>
              <IconButton onPress={() => {}} icon="thumbs-up-outline" size={16} />
              <View style={{ flex: 1 }} />
              <Button onPress={() => {}}>Comments (3)</Button>
            </Card.Actions>
          </Card>
          <Card style={styles.card} onPress={() => {}}>
            <Card.Cover source={{ uri: 'https://picsum.photos/700' }} />
            <Card.Title
              title="Book"
              subtitle="Some useful book to learn from"
              left={(props) => <Avatar.Icon {...props} icon="folder" style={{ backgroundColor: theme.colors.backdrop }} />}
              right={(props) => <IconButton {...props} icon="chevron-forward" onPress={() => {}} />}
            />
          </Card>
          <Card style={styles.card} onPress={() => {}}>
            <Card.Title title="More query" subtitle="Detail explanation of your query" />
            <Card.Actions>
              <IconButton onPress={() => {}} icon="thumbs-up-outline" size={16} />
              <View style={{ flex: 1 }} />
              <Button onPress={() => {}}>Comments</Button>
            </Card.Actions>
          </Card>
          <Card style={styles.card} onPress={() => {}}>
            <Card.Title title="One more query" subtitle="Detail explanation of your query" />
            <Card.Actions>
              <IconButton onPress={() => {}} icon="thumbs-up-outline" size={16} />
              <View style={{ flex: 1 }} />
              <Button onPress={() => {}}>Comments</Button>
            </Card.Actions>
          </Card>
          <Card style={styles.card} onPress={() => {}}>
            <Card.Title
              title="Some YouTube video title"
              subtitle="Useful youtube video"
              left={(props) => <Avatar.Icon {...props} icon="play" style={{ backgroundColor: theme.colors.backdrop }} />}
            />
            <Card.Cover source={{ uri: 'https://picsum.photos/700?1' }} />
            <Card.Actions>
              <IconButton onPress={() => {}} icon="thumbs-up-outline" size={16} />
              <Text>12</Text>
              <View style={{ flex: 1 }} />
              <Button onPress={() => {}}>Comments (3)</Button>
            </Card.Actions>
          </Card>
          <View style={{ paddingTop: 16, paddingBottom: 63, alignItems: 'center', justifyContent: 'center', maxWidth: 200, alignSelf: 'center' }}>
            <Text style={{ color: theme.colors.placeholder }}>[END]</Text>
          </View>
        </View>
      </ScrollView>
      <FAB
        style={[styles.fab, { backgroundColor: theme.colors.card }]}
        color={theme.colors.primary}
        icon="add"
        onPress={() => simpleAlert('Add', 'Post your query, share useful articles, videos and other resources.')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    // flex: 1,
    padding: 8,
    // paddingBottom: 96,
  },
  content: {
    width: '100%',
    alignSelf: 'center',
  },
  card: {
    marginBottom: 8,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 16,
    bottom: 16,
  },
});

export default DiscussionScreen;
