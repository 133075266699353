import { LinkingOptions } from '@react-navigation/native';

const linking: LinkingOptions = {
  prefixes: [
    'https://qurn.hamid.tech',
    'qurn://',
  ],
  config: {
    screens: {
      ModalScreen: {
        path: 'm',
        exact: true,
        parse: {
          body: (id) => '',
        },
        stringify: {
          body: (id) => '',
        },
      },
      RootStack: {
        screens: {
          LoginScreen: 'login',
          RegisterScreen: 'register',
          ForgotPasswordScreen: 'forgot-password',

          HomeContainer: 'home',
          HomeScreen: 'dashboard',
          DiscussionContainer: {
            path: 'discussion',
            screens: {
              DiscussionScreen: {
                path: 'feed',
                exact: true,
              },
              DiscussionDetailsScreen: {
                path: 'feed/:id',
                exact: true,
              },
            },
          },
          ScheduleContainer: {
            screens: {
              ScheduleScreen: {
                path: 'schedule',
              },
              ScheduleDetailsScreen: {
                path: 'schedule/details',
              },
            },
          },
          SettingsContainer: {
            path: 'settings',
            exact: true,
            screens: {
              SettingsScreen: {
                path: 'profile',
              },
              SettingsFormScreen: {
                path: 'update-profile',
              },
            },
          },
        },
      },

      // DetailsScreen: 'details',
      NotFoundScreen: '*',
    },
  },
};

export default linking;
