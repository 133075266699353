import React, { useState } from 'react';
import firebase from 'firebase';
import * as Analytics from 'expo-firebase-analytics';
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  View,
  TouchableOpacity,
} from 'react-native';
import { TextInput, Button, Text } from 'react-native-paper';

import KeyboardDismissableView from 'src/components/KeyboardDismissableView';
import { useTheme } from 'src/hooks/useTheme';
import { UseStackNavType } from 'src/types/nav';
import { simpleAlert } from 'src/utils/alert';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useNav } from 'src/hooks/useNav';

const LoginScreen = () => {
  const nav = useNav<UseStackNavType>();
  const { colors } = useTheme();
  const { isBrowser } = useDeviceDetect();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    if (!email || !password) {
      return simpleAlert('Error', 'Please enter both email and password');
    }
    setLoading(true);
    firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      Analytics.logEvent('login', { email });
    }).catch(function (error) {
      simpleAlert('Error', error.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleForgotPasswordPress = () => {
    nav.navigate('ForgotPasswordScreen');
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <KeyboardDismissableView style={[styles.form, { maxWidth: isBrowser ? 500 : undefined }]}>
          <Text style={styles.header}>Login</Text>
          <View style={styles.row}>
            <TextInput
              label="Username / Email"
              mode="outlined"
              autoCompleteType="username"
              keyboardType="email-address"
              autoCapitalize="none"
              onChangeText={setEmail}
              value={email}
            />
          </View>
          <View style={styles.row}>
            <TextInput
              label="Password"
              autoCompleteType="password"
              secureTextEntry
              mode="outlined"
              onChangeText={setPassword}
              value={password}
            />
            <TouchableOpacity onPress={handleForgotPasswordPress}>
              <Text style={{ textAlign: 'right', paddingTop: 8 }}>Forgot password?</Text>
            </TouchableOpacity>
          </View>
          <Button
            icon="log-in"
            mode="contained"
            onPress={handleLogin}
            style={styles.btn}
            labelStyle={styles.btnText}
            loading={loading}
            disabled={loading}
          >Login</Button>

          <View style={styles.singupRow}>
            <Text>Don’t have an account? </Text>
            <TouchableOpacity onPress={() => nav.replace('RegisterScreen', {})}>
              <Text style={[styles.link, { color: colors.primary }]}>Register</Text>
            </TouchableOpacity>
          </View>
        </KeyboardDismissableView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    padding: 24,
    paddingVertical: 62,
  },
  header: {
    fontSize: 36,
    marginBottom: 48,
  },
  input: {
  },
  btn: {
    marginTop: 8,
  },
  btnText: {
    lineHeight: 32,
  },
  row: {
    marginBottom: 16,
  },
  singupRow: {
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
  },
  link: {
    fontWeight: 'bold',
  },
});

export default LoginScreen;
