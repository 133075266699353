import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TextInput, Button, HelperText, Text } from 'react-native-paper';
import { useForm, Controller, ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { UseStackNavType } from 'src/types/nav';

// import { Theme } from 'react-native-paper/lib/typescript/types';
import { TextInputProps } from 'react-native-paper/lib/typescript/components/TextInput/TextInput';

// TODO: fix any
type InputTypes = {
  fieldState: ControllerFieldState;
  field: ControllerRenderProps;
} & TextInputProps;

const Input: React.FC<InputTypes> = ({
  fieldState,
  field,
  ...props
}: InputTypes) => {
  const { onChange, onBlur, value } = field;
  const { isDirty, error } = fieldState;
  return (
    <View style={styles.row}>
      <TextInput
        onChangeText={onChange}
        onBlur={onBlur}
        value={value}
        mode="outlined"
        returnKeyType="next"
        error={isDirty && !!error?.message}
        autoCapitalize="none"
        {...props}
      />
      {isDirty && !!error?.message ? <HelperText type="error">{error?.message}</HelperText> : null}
    </View>
  );
};

type PropTypes = {
  onSubmit: (data: unknown) => void;
  loading: boolean;
}

const Form: React.FC<PropTypes> = ({
  onSubmit,
  loading,
}: PropTypes) => {
  const navigation = useNavigation<UseStackNavType>();
  const { control, handleSubmit, getValues } = useForm();

  return (
    <View style={styles.container}>
      <Controller
        control={control}
        rules={{
          required: 'Email is required',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Entered value does not match email format',
          },
        }}
        name="email"
        defaultValue=""
        render={({ field, fieldState }) => (
          <Input
            label="Email"
            field={field}
            fieldState={fieldState}
            autoCompleteType="email"
            keyboardType="email-address"
            autoCapitalize="none"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: 'This field is required',
          minLength: {
            value: 6,
            message: 'Password should be of at-least 6 character long',
          },
        }}
        name="pass"
        defaultValue=""
        render={({ field, fieldState }) => (
          <Input
            label="Password"
            field={field}
            fieldState={fieldState}
            autoCompleteType="password"
            secureTextEntry
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: 'This field is required',
          validate: (repass) => {
            return getValues().pass === repass || 'Confirm password does not match with password';
          },
        }}
        name="repass"
        defaultValue=""
        render={({ field, fieldState }) => (
          <Input
            label="Password"
            field={field}
            fieldState={fieldState}
            autoCompleteType="password"
            secureTextEntry
          />
        )}
      />

      <Button
        icon=""
        mode="contained"
        onPress={handleSubmit(onSubmit)}
        style={styles.btn}
        labelStyle={styles.btnText}
        loading={loading}
        disabled={loading}
      >Register</Button>

      <View style={styles.textRow}>
        <Text>Already have an account? </Text>
        <Button mode="text" onPress={() => navigation.replace('LoginScreen', {})}>Login</Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 64,
  },
  btn: {
    marginTop: 32,
  },
  btnText: {
    lineHeight: 32,
  },
  row: {
    marginBottom: 16,
  },
  textRow: {
    flexDirection: 'row',
    marginTop: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Form;
