import { useEffect, useRef } from 'react';
import { registerForPushNotificationsAsync } from 'src/utils/notification';
import * as Notifications from 'expo-notifications';
import { Subscription } from '@unimodules/core';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/state/atom';

interface NotificationParams {
  shouldRegister?: boolean;
  onNotificationReceived?: (notification: Notifications.Notification) => void;
  onNotificationResponseReceived?: (event: Notifications.NotificationResponse) => void;
}

export const useNotification = ({
  shouldRegister = false,
  onNotificationReceived,
  onNotificationResponseReceived,
}: NotificationParams) => {
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();
  const user = useRecoilValue(userState);

  useEffect(() => {
    shouldRegister && registerForPushNotificationsAsync(user?.uid);

    if (onNotificationReceived) {
      notificationListener.current = Notifications.addNotificationReceivedListener(onNotificationReceived);
    }
    if (onNotificationResponseReceived) {
      responseListener.current = Notifications.addNotificationResponseReceivedListener(onNotificationResponseReceived);
    }

    return () => {
      notificationListener.current && Notifications.removeNotificationSubscription(notificationListener.current);
      responseListener.current && Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [shouldRegister, onNotificationReceived, onNotificationResponseReceived]);
};
