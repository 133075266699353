import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';

const NotFoundScreen = () => {
  const navigation = useNavigation();

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ fontSize: 42, marginBottom: 32 }}>404</Text>
      <Button onPress={() => navigation.goBack()}>Go back</Button>
    </View>
  );
};

export default NotFoundScreen;
