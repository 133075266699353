import React from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useNav } from 'src/hooks/useNav';
import { UseStackNavType } from 'src/types/nav';

const SettingsFormScreen = () => {
  const nav = useNav<UseStackNavType>();

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Hello world</Text>
      <Button onPress={() => nav.goBack()}>Close</Button>
    </View>
  );
};

export default SettingsFormScreen;
