import { atom } from 'recoil';
import { colorSchemeEnum, LanguageEnum, pnPreferenceEnum, UserDataType, UserPreference, UserType } from 'src/types/user';

export const userState = atom<UserType | null>({
  key: 'user',
  default: null,
});

export const userPreferenceState = atom<UserPreference>({
  key: 'user-preference',
  default: {
    pn: pnPreferenceEnum.All,
    colorScheme: colorSchemeEnum.SystemDefault,
    language: LanguageEnum.English,
  },
});

export const userDataState = atom<UserDataType | null>({
  key: 'user-data',
  default: null,
});
