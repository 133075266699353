import React, { FC } from 'react';
import { Keyboard, Platform, TouchableWithoutFeedback, View, ViewProps } from 'react-native';

type KDVPropTypes = ViewProps & {
  children?: React.ReactNode;
}

const WebKDV: FC<KDVPropTypes> = ({
  children,
  ...props
}: KDVPropTypes) => {
  return (<View {...props}>{children}</View>);
};

const NativeKDV: FC<KDVPropTypes> = ({
  children,
  ...props
}: KDVPropTypes) => {
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <WebKDV {...props}>{children}</WebKDV>
    </TouchableWithoutFeedback>
  );
};

const KeyboardDismissableView = Platform.OS === 'web' ? WebKDV : NativeKDV;

export default KeyboardDismissableView;
