import { useWindowDimensions } from 'react-native';

export const useDeviceDetect = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 640;
  const isTablet = !isMobile && width <= 960;
  const isLargeScreen = !isTablet && !isMobile;
  const isBrowser = isTablet || isLargeScreen;

  return { isMobile, isTablet, isLargeScreen, isBrowser };
};
