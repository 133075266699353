import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userDataState, userState } from 'src/state/atom';
import { UserType, UserDataType } from 'src/types/user';
import { captureException, captureMessage } from 'src/utils/sentry';

export const useUser = () => {
  const [user, setUser] = useRecoilState<UserType | null>(userState);
  const [userData, setUserData] = useRecoilState<UserDataType | null>(userDataState);
  const [authUser, setAUthUser] = useState<firebase.User | null>();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (u) => {
      if (!u) {
        setUserData(null);
        setUser(null);
        setAUthUser(null);
        return;
      }
      setAUthUser(u);
      const userID = u.uid;
      const token = await u.getIdTokenResult();
      setUser(u.toJSON() as any);
      const db = firebase.firestore();
      try {
        const doc = await db.collection('users').doc(userID).get();
        if (doc.exists) {
          const data = (doc.data() || {}) as UserDataType;
          data.claims = {
            permissions: token.claims.permissions || [],
          };
          setUserData({
            ...data,
            photoURL: u?.photoURL,
          });
        } else {
          captureMessage('User data not found');
          setUserData(null);
        }
      } catch (e) {
        captureException(e);
        setUserData(null);
      }
    });
  }, []);

  return { user, userData, setUser, setUserData, authUser };
};
