import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import DiscussionScreen from 'src/screens/Discussion';
import DiscussionDetailsScreen from 'src/screens/DiscussionDetails';

const Stack = createStackNavigator();

type Props = {
}

const DiscussionContainer: React.FC<Props> = () => (
  <Stack.Navigator initialRouteName="DiscussionScreen">
    <Stack.Screen name="DiscussionScreen" component={DiscussionScreen} />
    <Stack.Screen name="DiscussionDetailsScreen" component={DiscussionDetailsScreen} />
  </Stack.Navigator>
);

export default DiscussionContainer;
