interface UserProviderDataEntityType {
  uid: string;
  displayName?: null;
  photoURL?: null;
  email: string;
  phoneNumber?: null;
  providerId: string;
}
interface UserStsTokenManagerType {
  apiKey: string;
  refreshToken: string;
  accessToken: string;
  expirationTime: number;
}
interface UserMultiFactorType {
  enrolledFactors?: (null)[] | null;
}

export interface UserType {
  uid: string;
  displayName?: null;
  photoURL?: null;
  email: string;
  emailVerified: boolean;
  phoneNumber?: null;
  isAnonymous: boolean;
  tenantId?: null;
  providerData?: (UserProviderDataEntityType)[] | null;
  apiKey: string;
  appName: string;
  authDomain: string;
  stsTokenManager: UserStsTokenManagerType;
  redirectEventId?: null;
  lastLoginAt: string;
  createdAt: string;
  multiFactor: UserMultiFactorType;
}

export enum LanguageEnum {
  'English',
  'Urdu',
  'Arabic',
}

export enum colorSchemeEnum {
  'SystemDefault',
  'Light',
  'Dark',
}

export enum pnPreferenceEnum {
  'All',
  'None',
}

export interface UserPreference {
  language: LanguageEnum,
  colorScheme: colorSchemeEnum,
  pn: pnPreferenceEnum,
}

export enum PermissionEnum {
  'Admin' = 1,
  'ViewSchedule' = 2,
  'ManageSchedule' = 3,
  'ViewStudents' = 4,
};

export interface UserDataType {
  // name?: string;
  phone?: string;
  preference?: UserPreference;
  photoURL?: string | null;
  claims?: {
    permissions: PermissionEnum[];
  };
}
