import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { useNav } from 'src/hooks/useNav';
import { UseStackNavType } from 'src/types/nav';

const DATA = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    title: 'First Item',
    date: '1st Aug, 2021',
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    title: 'Second Item',
    date: '2nd Aug, 2021',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    title: 'Third Item',
    date: '3rd Aug, 2021',
  },
];

const Item: React.FC = ({ title, date, onPress, style }: any) => {
  return (
    <Card style={[styles.itemCard, style]} onPress={onPress}>
      <Text style={styles.title}>{date}</Text>
      <Text>{title}</Text>
    </Card>
  );
};

const ScheduleScreen = () => {
  const nav = useNav<UseStackNavType, StackNavigationOptions>({
    title: 'My schedules',
  });
  const { isBrowser } = useDeviceDetect();

  const handleItemPress = () => {
    nav.navigate('ScheduleDetailsScreen');
  };

  const renderItem = ({ item }: any) => (
    <Item {...item} onPress={handleItemPress} style={{ width: isBrowser ? 400 : undefined }} />
  );

  return (
    <FlatList
      data={DATA}
      renderItem={renderItem}
      keyExtractor={item => item.id}
      contentContainerStyle={[styles.container, { padding: isBrowser ? 16 : 4 }]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
  },
  content: {
    width: '100%',
  },
  item: {
  },
  itemCard: {
    marginTop: 4,
    marginHorizontal: 4,
    padding: 16,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 4,
  },
});

export default ScheduleScreen;
