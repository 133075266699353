import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import LoginScreen from 'src/screens/Login';
import RegisterScreen from 'src/screens/Register';
import NotFoundScreen from 'src/screens/NotFound';
import ForgotPasswordScreen from 'src/screens/ForgotPassword';

const Stack = createStackNavigator();

type Props = {
}

const AuthContainer: React.FC<Props> = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="LoginScreen" mode='modal'>
    <Stack.Screen name="LoginScreen" component={LoginScreen} options={{
      cardStyleInterpolator: CardStyleInterpolators.forFadeFromBottomAndroid,
    }} />
    <Stack.Screen name="RegisterScreen" component={RegisterScreen} options={{
      cardStyleInterpolator: CardStyleInterpolators.forFadeFromBottomAndroid,
    }} />
    <Stack.Screen name="ForgotPasswordScreen"
      options={{
        cardStyle: { backgroundColor: 'transparent' },
        cardStyleInterpolator: CardStyleInterpolators.forFadeFromBottomAndroid,
      }}
      component={ForgotPasswordScreen}
    />
    <Stack.Screen name="NotFoundScreen" component={NotFoundScreen} />
  </Stack.Navigator>
);

export default AuthContainer;
