import merge from 'deepmerge';
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from 'react-native-paper';
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';

export const DefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
export const DarkTheme = merge(PaperDarkTheme, NavigationDarkTheme);
// export const DarkTheme = merge(DT, {
//   colors: {
//     background: '#222',
//     surface: '#222',
//   },
// });
