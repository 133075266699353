import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import SettingsScreen from 'src/screens/Settings';
import SettingsFormScreen from 'src/screens/SettingsForm';

const Stack = createStackNavigator();

type Props = {
}

const SettingsContainer: React.FC<Props> = () => (
  <Stack.Navigator initialRouteName="SettingsScreen" mode="modal">
    <Stack.Screen name="SettingsScreen" component={SettingsScreen} />
    <Stack.Screen name="SettingsFormScreen" component={SettingsFormScreen} />
  </Stack.Navigator>
);

export default SettingsContainer;
