import { useRoute } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useNav } from 'src/hooks/useNav';
import { RootRouteProps, UseStackNavType } from 'src/types/nav';

const DiscussionDetailsScreen = () => {
  useNav<UseStackNavType, StackNavigationOptions>({
    title: '',
    headerBackTitle: '',
  });

  const route = useRoute<RootRouteProps<'DiscussionDetailsScreen'>>();

  return (
    <View style={styles.container}>
      <Text>Details {route.params.id}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DiscussionDetailsScreen;
