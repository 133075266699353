import { useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import firebase from 'firebase';
import React, { useCallback, useState } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, TouchableWithoutFeedback } from 'react-native';
import { Button, Text, TextInput } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { UseStackNavType } from 'src/types/nav';
import { simpleAlert } from 'src/utils/alert';

function validateEmail (email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

const ForgotPasswordScreen = () => {
  const safeArea = useSafeAreaInsets();
  const n = useNavigation<UseStackNavType>();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { isBrowser } = useDeviceDetect();

  const handleGoBack = useCallback(() => {
    if (n.canGoBack()) {
      n.goBack();
    } else {
      n.navigate('LoginScreen');
    }
  }, []);

  const handleSubmit = () => {
    if (!email || !validateEmail(email)) return simpleAlert('Error', 'Please enter a valid email');
    setLoading(true);
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      simpleAlert('Success', 'Please check your inbox for password reset email', [{
        text: 'OK',
        onPress: handleGoBack,
      }]);
    }).catch(e => {
      simpleAlert('ERROR', e.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <BlurView intensity={100} style={{ flex: 1 }}>
      <KeyboardAvoidingView style={{ flex: 1, paddingTop: safeArea.top }} behavior='padding'>
        <TouchableWithoutFeedback onPress={handleGoBack}>
          <View style={[styles.container, { alignItems: isBrowser ? 'center' : 'flex-end' }]}>
            <View style={{ flexGrow: 1 }}>
              <TouchableWithoutFeedback>
                <View style={[styles.content, { maxWidth: isBrowser ? 400 : undefined }]}>
                  <Text style={{ fontSize: 24 }}>Forgot</Text>
                  <Text style={{ fontSize: 24, marginBottom: 32 }}>Password</Text>
                  <TextInput
                    label="Email"
                    autoFocus={true}
                    style={{ marginBottom: 16 }}
                    enablesReturnKeyAutomatically={true}
                    mode="flat"
                    autoCompleteType="email"
                    keyboardType="email-address"
                    autoCapitalize="none"
                    returnKeyType="send"
                    onSubmitEditing={handleSubmit}
                    value={email}
                    onChangeText={setEmail}
                    autoCorrect={false}
                  />
                  <Button
                    mode='contained'
                    labelStyle={styles.btnText}
                    onPress={handleSubmit}
                    disabled={loading}
                    loading={loading}
                  >Reset password!</Button>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 32,
  },
  content: {
    width: '100%',
    alignSelf: 'center',
  },
  btnText: {
    lineHeight: 32,
  },
});

export default ForgotPasswordScreen;
