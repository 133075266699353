import React, { useMemo, useState } from 'react';
import { Platform, SectionList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Avatar, Divider, IconButton, List, Menu, Text, Button } from 'react-native-paper';
import firebase from 'firebase';
import * as Sentry from 'sentry-expo';
import { StackActions } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import Constants from 'expo-constants';
import { BlurView } from 'expo-blur';
import * as ImagePicker from 'expo-image-picker';

import { UseStackNavType } from 'src/types/nav';
import { useNav } from 'src/hooks/useNav';
import { useTheme } from 'src/hooks/useTheme';
import { simpleAlert } from 'src/utils/alert';
import { useUser } from 'src/hooks/useAuth';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { captureException } from 'src/utils/sentry';
import { colorSchemeEnum, LanguageEnum, pnPreferenceEnum, UserDataType } from 'src/types/user';
import ProfileForm from './ProfileForm';

const Item = ({ title, pKey, text, icon, onPress, options = [] }: any) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);

  const handleItemPress = (value: string) => {
    setVisible(false);
    onPress && onPress(pKey, value);
  };

  const toggleMenu = () => setVisible(!visible);

  const DropDown = options && options.length
    ? () => (
      <Menu
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={<List.Icon icon="chevron-forward" />}
      >
        <List.Subheader>{title}</List.Subheader>
        <Divider key="divider" />
        {options.map((opt: string) => (
          <Menu.Item key={opt} onPress={() => handleItemPress(opt)} title={opt} />
        ))}
      </Menu>
    )
    : undefined;

  return (
    <List.Item
      title={title}
      left={() => <List.Icon icon={icon} />}
      style={{ backgroundColor: theme.colors.card }}
      right={DropDown}
      disabled={!onPress}
      onPress={DropDown ? toggleMenu : () => onPress?.(pKey)}
      description={text || null}
    />
  );
};

interface ItemType {
  title: string;
  text?: LanguageEnum | pnPreferenceEnum | colorSchemeEnum | string;
  icon: string;
  options?: string[];
  onPress?: (...args: any[]) => void;
  pKey?: string;
}

const SettingsScreen = () => {
  const theme = useTheme();
  const { userData, setUserData, authUser } = useUser();
  const { isBrowser } = useDeviceDetect();

  const renderHeaderRight = () => (
    <IconButton icon="log-out-outline" onPress={handleLogout} />
  );

  const nav = useNav<UseStackNavType, StackNavigationOptions>({
    title: 'Profile',
    headerRight: renderHeaderRight,
  });

  const handleFieldEdit = (pKey: string) => {
    nav.navigate('ModalScreen', {
      body: ProfileForm,
    });
  };

  const handlePreferenceChange = (pKey: string, value: string) => {
    if (!authUser) return;
    const db = firebase.firestore();
    try {
      db.collection('users').doc(authUser.uid).update({
        [`preference.${pKey}`]: value,
      }).then(res => {
        setUserData({
          ...userData,
          preference: {
            ...userData?.preference,
            [pKey]: value,
          } as UserDataType['preference'],
        });
      });
    } catch (e) {
      captureException(e);
    }
  };

  const DATA = useMemo<{title: string, titleRight?: React.ReactNode, data: ItemType[]}[]>(() => [
    {
      title: 'Personal details',
      titleRight: (
        <Button
          onPress={() => handleFieldEdit('name')}
          mode="text"
          compact={true}
          style={{ marginRight: 8 }}
        >Edit</Button>
      ),
      data: [
        {
          title: 'Name',
          pKey: 'name',
          text: authUser?.displayName || 'Please enter your name',
          icon: 'person-outline',
        }, {
          title: 'Email',
          pKey: 'email',
          text: authUser?.email || undefined,
          icon: 'mail-outline',
        }, {
          title: 'Phone',
          pKey: 'phone',
          text: userData?.phone || 'Please enter your phone number',
          icon: 'phone-portrait-outline',
        },
      ],
    },
    {
      title: 'Preferences',
      data: [
        {
          title: 'Language',
          pKey: 'language',
          text: userData?.preference?.language,
          icon: 'earth',
          options: ['English', 'Urdu', 'Arabic'],
          onPress: handlePreferenceChange,
        }, {
          title: 'Theme (Color scheme)',
          pKey: 'colorScheme',
          text: userData?.preference?.colorScheme,
          icon: 'contrast-outline',
          options: ['System', 'Dark', 'Light'],
          onPress: handlePreferenceChange,
        }, {
          title: 'Notifications',
          pKey: 'pn',
          text: userData?.preference?.pn,
          icon: 'notifications-outline',
          options: ['All', 'None'],
          onPress: handlePreferenceChange,
        },
      ],
    },
  ], [userData, authUser]);

  const handleLogout = () => {
    nav.dispatch(StackActions.popToTop());
    firebase.auth().signOut().catch(e => {
      Sentry.Browser.captureException(e);
    });
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (result.cancelled || !authUser) return;

    const { uri } = result as any;
    const ref = firebase.storage().ref(authUser.uid).child('profile');
    let snapshot;
    if (Platform.OS === 'web') {
      snapshot = await ref.putString(uri, 'data_url');
    } else {
      const blob = await fetch(uri).then(res => res.blob());
      snapshot = await ref.put(blob);
    }
    if (snapshot.state === 'success') {
      ref.getDownloadURL().then(photoURL => {
        setUserData({ ...userData, photoURL });
        return authUser.updateProfile({ photoURL });
      });
    } else {
      simpleAlert('Error', 'Profile image update failed');
    }
  };

  return (
    <SectionList
      contentContainerStyle={[styles.container, { maxWidth: isBrowser ? 400 : undefined }]}
      sections={DATA}
      keyExtractor={(item, index) => item.title}
      renderItem={({ item }) => <Item {...item} />}
      renderSectionHeader={({ section: { title, titleRight } }) => (
        <BlurView intensity={90} style={styles.sectionTitle}>
          <List.Subheader>{title}</List.Subheader>
          {titleRight}
        </BlurView>
      )}
      renderSectionFooter={() => <View style={{ height: 16 }} />}
      ListHeaderComponent={() => (
        <View style={{ paddingVertical: 64, justifyContent: 'center', alignItems: 'center' }}>
          <TouchableOpacity onPress={pickImage}>
            {userData?.photoURL
              ? (
                <Avatar.Image source={{ uri: userData.photoURL }} size={96} />
              )
              : (
                <Avatar.Text label="QU" size={96} />
              )}
          </TouchableOpacity>
        </View>
      )}
      ListFooterComponent={() => (
        <View style={styles.footer}>
          <Text style={{ color: theme.colors.placeholder }}>v{Constants.manifest?.version}</Text>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center',
  },
  sectionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    paddingTop: 0,
    paddingBottom: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SettingsScreen;
