import React, { useCallback, useState } from 'react';
import firebase from 'firebase';
import * as Analytics from 'expo-firebase-analytics';
import {
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  View,
} from 'react-native';
import { Text } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { simpleAlert } from 'src/utils/alert';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';

import Form from './Form';

const RegisterScreen = () => {
  const safeArea = useSafeAreaInsets();
  const [loading, setLoading] = useState(false);
  const { isBrowser } = useDeviceDetect();

  const handleSubmit = useCallback(({ email, pass }) => {
    setLoading(true);
    firebase.auth().createUserWithEmailAndPassword(email, pass)
      .then(() => {
        Analytics.logEvent('sign_up', { email });
      })
      .catch((error) => simpleAlert('Error', error.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <KeyboardAvoidingView behavior='padding' enabled={Platform.OS === 'ios'} style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={[styles.container, { paddingTop: safeArea.top, paddingBottom: safeArea.bottom }]}>
        <View style={[styles.content, { maxWidth: isBrowser ? 400 : undefined }]}>
          <Text style={styles.header}>Register</Text>
          <Form onSubmit={handleSubmit} loading={loading} />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
  header: {
    marginTop: 64,
    fontSize: 36,
    marginBottom: 48,
  },
});

export default RegisterScreen;
